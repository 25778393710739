import React, {useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import mainLogo_black from '../../asset/img/landing/main_logo_black.svg'
import listImg from "../../asset/img/list.svg";
import {Col, Row} from "reactstrap";
import mattress from "../../data/mattress";
import nav_img from "../../asset/img/bulb2.png";
import {landingScroll} from "./landingScroll";
import {BrowserView, isMobile, MobileView} from "react-device-detect";
import MobileSideNav from "./MobileSideNav";


const LandingNav = () => {
    useEffect(() => {
        if (!isMobile) {
            window.addEventListener('scroll', landingScroll);
            return () => window.removeEventListener('scroll', landingScroll);
        }
    }, []);
    const [click, setClick] = useState(false);
    const [itemSelect, setItemSelect] = useState(0);
    const [showItem, setShowItem] = useState(false);
    const handleClick = () => setClick(!click);
    const handleMouseEnter = () => {
        if (!isMobile) {
            const windowHeight = window.innerHeight;
            const scrollTop = window.scrollY;
            let alpha = (scrollTop / windowHeight) * 2;
            if (alpha >= 1) setShowItem(true)
        }
    }
    return (
        <>
            <nav className="landing_nav">
                <div className='position-relative w-100'>
                    <div className="nav-container">
                        <div className="nav-icon"
                             onClick={handleClick}>
                            <img src={listImg}/>
                        </div>
                        <NavLink exact to="/" className="nav-logo">
                            <img id='main_logo_img' src={mainLogo_black}/>
                        </NavLink>

                        <ul className={click ? "nav-menu active" : "nav-menu"}>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/introduce"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    브랜드 스토리
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={() => setShowItem(false)}
                                    exact
                                    to="/list"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    매트리스
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/showRoom"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    매장안내
                                </NavLink>
                            </li>
                        </ul>
                        <ul className='nav-menu nav-right-menu'>
                            <li className='nav-right-item'>
                                <NavLink
                                    exact
                                    to="/customer"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    고객센터
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <MobileView>
                        <MobileSideNav handleClick={handleClick} show={click}/>
                    </MobileView>
                    <BrowserView>
                        <div
                            className={`navbar_more_wrapper ${showItem ? 'active' : ''}`}
                            // className='navbar_more_wrapper active'
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={() => setShowItem(false)}>
                            <div className='more_item_wrapper'>
                                <Row className='navbar_item_wrapper g-0'>
                                    <Col xs={2} className='left_text_wrapper h-100'>
                                        <span>매트리스</span>
                                    </Col>
                                    <Col className='h-100' xs={6}>
                                        <div className='nav_mattress_wrapper'>
                                            {mattress.map((item, index) => {
                                                return (
                                                    <div key={index} className='nav_mattress_item' onMouseLeave={() => setItemSelect(0)} onMouseEnter={() => setItemSelect(index + 1)}>
                                                        <div className='position-relative nav_mattress_img_wrapper'>
                                                            <Link to={`/detail/${item.no}`}>
                                                                <img src={item.nav_img}/>
                                                                {itemSelect === index + 1 &&
                                                                <div className='nav_mattress_back'>
                                                        <span>
                                                            자세히 보기
                                                        </span>

                                                                </div>
                                                                }
                                                            </Link>
                                                        </div>
                                                        <span>{item.title}</span>


                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Col>
                                    <Col xs-={4} className='right_text_wrapper'>
                                        {itemSelect === 0 ?
                                            <div style={{textAlign: 'left'}}>
                                                <span>클라우드 매직만의 외장재<br/> 수면의 질을 높여줍니다.</span>
                                                <hr style={{margin: '2rem 0'}}/>
                                                <img id='nav_detail_img' src={nav_img}/>
                                            </div>
                                            :
                                            <div style={{textAlign: 'left'}}>
                                                <h3>{mattress[itemSelect - 1].title}</h3>
                                                <div className='text-center'>
                                                    <img id='nav_detail_hard_type' src={mattress[itemSelect - 1].hard_type_img}/>
                                                </div>
                                                <hr style={{margin: '2rem 0'}}/>
                                                <img id='nav_detail_img' src={mattress[itemSelect - 1].nav_detail_img}/>
                                            </div>
                                        }

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </BrowserView>

                </div>
            </nav>

        </>
    );

}
export default LandingNav;
