import React, {useState} from 'react';
import {Button, Card, CardText, CardTitle, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';
import starImg from '../../asset/img/detail/star.svg'
import testImg from '../../asset/img/detail/one/0429 매트리스_001.jpg'
import mattress from "../../data/mattress";
import detail_footer from "../../asset/img/mattress_img/detail_footer.png"
import detail_footer2 from "../../asset/img/mattress_img/common_detail.png"

const DetailTab = ({detailNo}) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div className='detail_tab_wrapper'>
            <Nav tabs className='detail_tab'>
                <NavItem>
                    <NavLink
                        className={classnames({active: activeTab === '1'})}
                        onClick={() => {
                            toggle('1');
                        }}
                    >
                        상품정보
                    </NavLink>
                </NavItem>
                {/*<NavItem>*/}
                {/*    <NavLink*/}
                {/*        className={classnames({active: activeTab === '2'})}*/}
                {/*        onClick={() => {*/}
                {/*            toggle('2');*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        상품리뷰*/}
                {/*    </NavLink>*/}
                {/*</NavItem>*/}
                {/*<NavItem>*/}
                {/*    <NavLink*/}
                {/*        className={classnames({active: activeTab === '3'})}*/}
                {/*        onClick={() => {*/}
                {/*            toggle('3');*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        상품문의*/}
                {/*    </NavLink>*/}
                {/*</NavItem>*/}
                {/*<NavItem>*/}
                {/*    <NavLink*/}
                {/*        className={classnames({active: activeTab === '4'})}*/}
                {/*        onClick={() => {*/}
                {/*            toggle('4');*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        구매안내*/}
                {/*    </NavLink>*/}
                {/*</NavItem>*/}
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row className='justify-content-center'>
                        <Col sm="12" className='detail_main_img_wrapper'>
                            {mattress[detailNo].sleep_maker ?
                                <div className='sleep_maker_detail_wrapper'>
                                    <img src={mattress[detailNo].detail_main_img[0]}/>
                                    <img src={mattress[detailNo].detail_main_img[1]}/>
                                    <div className='sleep_maker_movie_wrapper'>
                                        <video controls src={mattress[detailNo].detail_main_img[3]}/>
                                        <div className='sleep_maker_advantage'>
                                            <div className='advantage_header'>
                                                <div className='blank_advantage'/>
                                                <h2>Sleep Maker의 장점은?</h2>
                                            </div>
                                            <div>
                                                <span style={{color : '#888888',marginTop : '1rem', fontSize : '1.2rem'}}>전자파가 나오는 볼을 이용한 테스트</span>
                                            </div>
                                            <div className='advantage_content'>
                                                <div className='advantage_item'>
                                                    <img src={mattress[detailNo].detail_main_img[4]}/>
                                                </div>
                                                <div className='advantage_item'>
                                                    <img src={mattress[detailNo].detail_main_img[5]}/>
                                                </div>
                                            </div>
                                            <div>
                                                    <span><strong>Sleep Maker 원단</strong>은 일자 막대기 모양으로 전자파를 빨아드림으로서<br/>
                                                        <strong>전자파 차단</strong> 및 <strong>수맥 차단</strong> 효과를 가져오지만<br/>
                                                        <strong>타사 원단</strong>은 전자파를 흡수하지 못함</span>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={mattress[detailNo].detail_main_img[2]}/>
                                </div>
                                :
                                <img src={mattress[detailNo].detail_main_img}/>
                            }
                            {
                                detailNo >= 6 ? <img src={detail_footer2}/> : <img src={detail_footer}/>
                            }

                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <div className='detail_review_wrapper'>
                        <Row>
                            <Col xs={6} className='review_header_left'>
                                <h4>상품평 112</h4>
                            </Col>
                            <Col xs={6} className='review_header_right'>
                                <span>최신순</span>
                                <span>|</span>
                                <span>평점순</span>
                            </Col>
                            <hr/>
                            <Col xs={12} className='review_item'>
                                <Row>
                                    <Col xs={12} className='review_item_header'>
                                        <div className='star_wrapper'>
                                            <img src={starImg} id='starImg'/>
                                            <img src={starImg} id='starImg'/>
                                            <img src={starImg} id='starImg'/>
                                            <img src={starImg} id='starImg'/>
                                            <img src={starImg} id='starImg'/>
                                            <span className='star_count'>5</span>
                                        </div>
                                        <div className='review_user_info'><span>닉네임</span><span>21.06.26</span></div>
                                        <span>제품명</span>
                                    </Col>
                                    <Col xs={12} className='review_item_content'>
                                        <Row className='g-0'>
                                            <Col xs={12} className='review_content_img'>
                                                <img src={testImg}/>
                                                <img src={testImg}/>
                                                <img src={testImg}/>
                                                <img src={testImg}/>
                                                <img src={testImg}/>
                                            </Col>
                                            <Col xs={12} className='review_content_txt'>
                                                <p>
                                                    너무 좋아요!
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} className='review_item'>
                                <Row>
                                    <Col xs={12} className='review_item_header'>
                                        <div className='star_wrapper'>
                                            <img src={starImg} id='starImg'/>
                                            <img src={starImg} id='starImg'/>
                                            <img src={starImg} id='starImg'/>
                                            <img src={starImg} id='starImg'/>
                                            <img src={starImg} id='starImg'/>
                                            <span className='star_count'>5</span>
                                        </div>
                                        <div className='review_user_info'><span>닉네임</span><span>21.06.26</span></div>
                                        <span>제품명</span>
                                    </Col>
                                    <Col xs={12} className='review_item_content'>
                                        <Row className='g-0'>
                                            <Col xs={12} className='review_content_img'>
                                                <img src={testImg}/>
                                                <img src={testImg}/>
                                                <img src={testImg}/>
                                                <img src={testImg}/>
                                                <img src={testImg}/>
                                            </Col>
                                            <Col xs={12} className='review_content_txt'>
                                                <p>
                                                    너무 좋아요!
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col> <Col xs={12} className='review_item'>
                            <Row>
                                <Col xs={12} className='review_item_header'>
                                    <div className='star_wrapper'>
                                        <img src={starImg} id='starImg'/>
                                        <img src={starImg} id='starImg'/>
                                        <img src={starImg} id='starImg'/>
                                        <img src={starImg} id='starImg'/>
                                        <img src={starImg} id='starImg'/>
                                        <span className='star_count'>5</span>
                                    </div>
                                    <div className='review_user_info'><span>닉네임</span><span>21.06.26</span></div>
                                    <span>제품명</span>
                                </Col>
                                <Col xs={12} className='review_item_content'>
                                    <Row className='g-0'>
                                        <Col xs={12} className='review_content_img'>
                                            <img src={testImg}/>
                                            <img src={testImg}/>
                                            <img src={testImg}/>
                                            <img src={testImg}/>
                                            <img src={testImg}/>
                                        </Col>
                                        <Col xs={12} className='review_content_txt'>
                                            <p>
                                                너무 좋아요!
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        </Row>

                    </div>
                </TabPane>
                <TabPane tabId="3">
                    <Row>
                        <Col sm="6">
                            <Card body>
                                <CardTitle>Special Title Treatment</CardTitle>
                                <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                <Button>Go somewhere</Button>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card body>
                                <CardTitle>Special Title Treatment</CardTitle>
                                <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                <Button>Go somewhere</Button>
                            </Card>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="4">
                    <Row>
                        <Col sm="6">
                            <Card body>
                                <CardTitle>Special Title Treatment</CardTitle>
                                <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                <Button>Go somewhere</Button>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card body>
                                <CardTitle>Special Title Treatment</CardTitle>
                                <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                <Button>Go somewhere</Button>
                            </Card>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </div>
    );
}

export default DetailTab;
