import detail_big_1_1 from '../asset/img/mattress_img/1/detail/detail_big_1_1.png';
import detail_big_1_2 from '../asset/img/mattress_img/1/detail/detail_big_1_2.png';
import detail_big_1_3 from '../asset/img/mattress_img/1/detail/detail_big_1_3.png';
import detail_big_1_4 from '../asset/img/mattress_img/1/detail/detail_big_1_4.png';
import detail_big_1_5 from '../asset/img/mattress_img/1/detail/detail_big_1_5.png';
import detail_small_1_1 from '../asset/img/mattress_img/1/detail/detail_small_1_1.png';
import detail_small_1_2 from '../asset/img/mattress_img/1/detail/detail_small_1_2.png';
import detail_small_1_3 from '../asset/img/mattress_img/1/detail/detail_small_1_3.png';
import detail_small_1_4 from '../asset/img/mattress_img/1/detail/detail_small_1_4.png';
import detail_small_1_5 from '../asset/img/mattress_img/1/detail/detail_small_1_5.png';

import detail_big_2_1 from '../asset/img/mattress_img/2/detail/detail_big_2_1.png';
import detail_big_2_2 from '../asset/img/mattress_img/2/detail/detail_big_2_2.png';
import detail_big_2_3 from '../asset/img/mattress_img/2/detail/detail_big_2_3.png';
import detail_big_2_4 from '../asset/img/mattress_img/2/detail/detail_big_2_4.png';
import detail_big_2_5 from '../asset/img/mattress_img/2/detail/detail_big_2_5.png';
import detail_small_2_1 from '../asset/img/mattress_img/2/detail/detail_small_2_1.png';
import detail_small_2_2 from '../asset/img/mattress_img/2/detail/detail_small_2_2.png';
import detail_small_2_3 from '../asset/img/mattress_img/2/detail/detail_small_2_3.png';
import detail_small_2_4 from '../asset/img/mattress_img/2/detail/detail_small_2_4.png';
import detail_small_2_5 from '../asset/img/mattress_img/2/detail/detail_small_2_5.png';

import detail_big_4_1 from '../asset/img/mattress_img/4/detail/detail_big_4_1.png';
import detail_big_4_2 from '../asset/img/mattress_img/4/detail/detail_big_4_2.png';
import detail_big_4_3 from '../asset/img/mattress_img/4/detail/detail_big_4_3.png';
import detail_big_4_4 from '../asset/img/mattress_img/4/detail/detail_big_4_4.png';
import detail_big_4_5 from '../asset/img/mattress_img/4/detail/detail_big_4_5.png';
import detail_small_4_1 from '../asset/img/mattress_img/4/detail/detail_small_4_1.png';
import detail_small_4_2 from '../asset/img/mattress_img/4/detail/detail_small_4_2.png';
import detail_small_4_3 from '../asset/img/mattress_img/4/detail/detail_small_4_3.png';
import detail_small_4_4 from '../asset/img/mattress_img/4/detail/detail_small_4_4.png';
import detail_small_4_5 from '../asset/img/mattress_img/4/detail/detail_small_4_5.png';

import detail_big_5_1 from '../asset/img/mattress_img/5/detail/detail_big_5_1.png';
import detail_big_5_2 from '../asset/img/mattress_img/5/detail/detail_big_5_2.png';
import detail_big_5_3 from '../asset/img/mattress_img/5/detail/detail_big_5_3.png';
import detail_big_5_4 from '../asset/img/mattress_img/5/detail/detail_big_5_4.png';
import detail_big_5_5 from '../asset/img/mattress_img/5/detail/detail_big_5_5.png';
import detail_small_5_1 from '../asset/img/mattress_img/5/detail/detail_small_5_1.png';
import detail_small_5_2 from '../asset/img/mattress_img/5/detail/detail_small_5_2.png';
import detail_small_5_3 from '../asset/img/mattress_img/5/detail/detail_small_5_3.png';
import detail_small_5_4 from '../asset/img/mattress_img/5/detail/detail_small_5_4.png';
import detail_small_5_5 from '../asset/img/mattress_img/5/detail/detail_small_5_5.png';

import detail_big_9_1 from '../asset/img/mattress_img/9/detail/detail_big_9_1.jpg';
import detail_big_9_2 from '../asset/img/mattress_img/9/detail/detail_big_9_2.jpg';
import detail_big_9_3 from '../asset/img/mattress_img/9/detail/detail_big_9_3.jpg';
import detail_big_9_4 from '../asset/img/mattress_img/9/detail/detail_big_9_4.jpg';
import detail_big_9_5 from '../asset/img/mattress_img/9/detail/detail_big_9_5.jpg';
import detail_big_9_6 from '../asset/img/mattress_img/9/detail/detail_big_9_6.jpg';
import detail_small_9_1 from '../asset/img/mattress_img/9/detail/detail_small_9_1.jpg';
import detail_small_9_2 from '../asset/img/mattress_img/9/detail/detail_small_9_2.jpg';
import detail_small_9_3 from '../asset/img/mattress_img/9/detail/detail_small_9_3.jpg';
import detail_small_9_4 from '../asset/img/mattress_img/9/detail/detail_small_9_4.jpg';
import detail_small_9_5 from '../asset/img/mattress_img/9/detail/detail_small_9_5.jpg';
import detail_small_9_6 from '../asset/img/mattress_img/9/detail/detail_small_9_6.jpg';

import detail_big_10_1 from '../asset/img/mattress_img/10/detail/detail_big_10_1.jpg';
import detail_big_10_2 from '../asset/img/mattress_img/10/detail/detail_big_10_2.jpg';
import detail_big_10_3 from '../asset/img/mattress_img/10/detail/detail_big_10_3.jpg';
import detail_big_10_4 from '../asset/img/mattress_img/10/detail/detail_big_10_4.jpg';
import detail_big_10_5 from '../asset/img/mattress_img/10/detail/detail_big_10_5.jpg';
import detail_big_10_6 from '../asset/img/mattress_img/10/detail/detail_big_10_6.jpg';
import detail_big_10_8 from '../asset/img/mattress_img/10/detail/detail_big_10_8.jpg';
import detail_small_10_1 from '../asset/img/mattress_img/10/detail/detail_small_10_1.jpg';
import detail_small_10_2 from '../asset/img/mattress_img/10/detail/detail_small_10_2.jpg';
import detail_small_10_3 from '../asset/img/mattress_img/10/detail/detail_small_10_3.jpg';
import detail_small_10_4 from '../asset/img/mattress_img/10/detail/detail_small_10_4.jpg';
import detail_small_10_5 from '../asset/img/mattress_img/10/detail/detail_small_10_5.jpg';
import detail_small_10_6 from '../asset/img/mattress_img/10/detail/detail_small_10_6.jpg';
import detail_small_10_8 from '../asset/img/mattress_img/10/detail/detail_small_10_8.jpg';

import detail_big_11_1 from '../asset/img/mattress_img/11/detail/detail_big_11_1.jpg';
import detail_big_11_2 from '../asset/img/mattress_img/11/detail/detail_big_11_2.jpg';
import detail_big_11_3 from '../asset/img/mattress_img/11/detail/detail_big_11_3.jpg';
import detail_big_11_4 from '../asset/img/mattress_img/11/detail/detail_big_11_4.jpg';
import detail_big_11_5 from '../asset/img/mattress_img/11/detail/detail_big_11_5.jpg';
import detail_big_11_6 from '../asset/img/mattress_img/11/detail/detail_big_11_6.jpg';
import detail_big_11_7 from '../asset/img/mattress_img/11/detail/detail_big_11_7.jpg';
import detail_small_11_1 from '../asset/img/mattress_img/11/detail/detail_small_11_1.jpg';
import detail_small_11_2 from '../asset/img/mattress_img/11/detail/detail_small_11_2.jpg';
import detail_small_11_3 from '../asset/img/mattress_img/11/detail/detail_small_11_3.jpg';
import detail_small_11_4 from '../asset/img/mattress_img/11/detail/detail_small_11_4.jpg';
import detail_small_11_5 from '../asset/img/mattress_img/11/detail/detail_small_11_5.jpg';
import detail_small_11_6 from '../asset/img/mattress_img/11/detail/detail_small_11_6.jpg';
import detail_small_11_7 from '../asset/img/mattress_img/11/detail/detail_small_11_7.jpg';

import detail_big_12_1 from '../asset/img/mattress_img/12/detail/detail_big_12_1.jpg';
import detail_big_12_2 from '../asset/img/mattress_img/12/detail/detail_big_12_2.jpg';
import detail_big_12_3 from '../asset/img/mattress_img/12/detail/detail_big_12_3.jpg';
import detail_big_12_4 from '../asset/img/mattress_img/12/detail/detail_big_12_4.jpg';
import detail_big_12_5 from '../asset/img/mattress_img/12/detail/detail_big_12_5.jpg';
import detail_big_12_6 from '../asset/img/mattress_img/12/detail/detail_big_12_6.jpg';
import detail_big_12_7 from '../asset/img/mattress_img/12/detail/detail_big_12_7.jpg';
import detail_small_12_1 from '../asset/img/mattress_img/12/detail/detail_small_12_1.jpg';
import detail_small_12_2 from '../asset/img/mattress_img/12/detail/detail_small_12_2.jpg';
import detail_small_12_3 from '../asset/img/mattress_img/12/detail/detail_small_12_3.jpg';
import detail_small_12_4 from '../asset/img/mattress_img/12/detail/detail_small_12_4.jpg';
import detail_small_12_5 from '../asset/img/mattress_img/12/detail/detail_small_12_5.jpg';
import detail_small_12_6 from '../asset/img/mattress_img/12/detail/detail_small_12_6.jpg';
import detail_small_12_7 from '../asset/img/mattress_img/12/detail/detail_small_12_7.jpg';

import detail_big_13_1 from '../asset/img/mattress_img/13/detail/detail_big_13_1.jpg';
import detail_big_13_2 from '../asset/img/mattress_img/13/detail/detail_big_13_2.jpg';
import detail_big_13_3 from '../asset/img/mattress_img/13/detail/detail_big_13_3.jpg';
import detail_big_13_4 from '../asset/img/mattress_img/13/detail/detail_big_13_4.jpg';
import detail_big_13_5 from '../asset/img/mattress_img/13/detail/detail_big_13_5.jpg';
import detail_big_13_6 from '../asset/img/mattress_img/13/detail/detail_big_13_6.jpg';
import detail_big_13_7 from '../asset/img/mattress_img/13/detail/detail_big_13_7.jpg';
import detail_small_13_1 from '../asset/img/mattress_img/13/detail/detail_small_13_1.jpg';
import detail_small_13_2 from '../asset/img/mattress_img/13/detail/detail_small_13_2.jpg';
import detail_small_13_3 from '../asset/img/mattress_img/13/detail/detail_small_13_3.jpg';
import detail_small_13_4 from '../asset/img/mattress_img/13/detail/detail_small_13_4.jpg';
import detail_small_13_5 from '../asset/img/mattress_img/13/detail/detail_small_13_5.jpg';
import detail_small_13_6 from '../asset/img/mattress_img/13/detail/detail_small_13_6.jpg';
import detail_small_13_7 from '../asset/img/mattress_img/13/detail/detail_small_13_7.jpg';
import detail_mobile_13_1 from '../asset/img/mattress_img/13/detail/detail_mobile_13_1.jpg';
import detail_mobile_13_2 from '../asset/img/mattress_img/13/detail/detail_mobile_13_2.jpg';
import detail_mobile_13_3 from '../asset/img/mattress_img/13/detail/detail_mobile_13_3.jpg';
import detail_mobile_13_4 from '../asset/img/mattress_img/13/detail/detail_mobile_13_4.jpg';
import detail_mobile_13_5 from '../asset/img/mattress_img/13/detail/detail_mobile_13_5.jpg';
import detail_mobile_13_6 from '../asset/img/mattress_img/13/detail/detail_mobile_13_6.jpg';
import detail_mobile_13_7 from '../asset/img/mattress_img/13/detail/detail_mobile_13_7.jpg';


import detail_big_14_1 from '../asset/img/mattress_img/14/detail/detail_big_14_1.jpg';
import detail_big_14_2 from '../asset/img/mattress_img/14/detail/detail_big_14_2.jpg';
import detail_big_14_3 from '../asset/img/mattress_img/14/detail/detail_big_14_3.jpg';
import detail_big_14_4 from '../asset/img/mattress_img/14/detail/detail_big_14_4.jpg';
import detail_big_14_5 from '../asset/img/mattress_img/14/detail/detail_big_14_5.jpg';
import detail_big_14_6 from '../asset/img/mattress_img/14/detail/detail_big_14_6.jpg';
import detail_big_14_7 from '../asset/img/mattress_img/14/detail/detail_big_14_7.jpg';
import detail_small_14_1 from '../asset/img/mattress_img/14/detail/detail_small_14_1.jpg';
import detail_small_14_2 from '../asset/img/mattress_img/14/detail/detail_small_14_2.jpg';
import detail_small_14_3 from '../asset/img/mattress_img/14/detail/detail_small_14_3.jpg';
import detail_small_14_4 from '../asset/img/mattress_img/14/detail/detail_small_14_4.jpg';
import detail_small_14_5 from '../asset/img/mattress_img/14/detail/detail_small_14_5.jpg';
import detail_small_14_6 from '../asset/img/mattress_img/14/detail/detail_small_14_6.jpg';
import detail_small_14_7 from '../asset/img/mattress_img/14/detail/detail_small_14_7.jpg';
import detail_mobile_14_1 from '../asset/img/mattress_img/14/detail/detail_mobile_14_1.jpg';
import detail_mobile_14_2 from '../asset/img/mattress_img/14/detail/detail_mobile_14_2.jpg';
import detail_mobile_14_3 from '../asset/img/mattress_img/14/detail/detail_mobile_14_3.jpg';
import detail_mobile_14_4 from '../asset/img/mattress_img/14/detail/detail_mobile_14_4.jpg';
import detail_mobile_14_5 from '../asset/img/mattress_img/14/detail/detail_mobile_14_5.jpg';
import detail_mobile_14_6 from '../asset/img/mattress_img/14/detail/detail_mobile_14_6.jpg';
import detail_mobile_14_7 from '../asset/img/mattress_img/14/detail/detail_mobile_14_7.jpg';

import detail_big_15_1 from '../asset/img/mattress_img/15/detail/detail_big_15_1.jpg';
import detail_big_15_2 from '../asset/img/mattress_img/15/detail/detail_big_15_2.jpg';
import detail_big_15_3 from '../asset/img/mattress_img/15/detail/detail_big_15_3.jpg';
import detail_big_15_4 from '../asset/img/mattress_img/15/detail/detail_big_15_4.jpg';
import detail_big_15_5 from '../asset/img/mattress_img/15/detail/detail_big_15_5.jpg';
import detail_big_15_6 from '../asset/img/mattress_img/15/detail/detail_big_15_6.jpg';
import detail_big_15_7 from '../asset/img/mattress_img/15/detail/detail_big_15_7.jpg';
import detail_small_15_1 from '../asset/img/mattress_img/15/detail/detail_small_15_1.jpg';
import detail_small_15_2 from '../asset/img/mattress_img/15/detail/detail_small_15_2.jpg';
import detail_small_15_3 from '../asset/img/mattress_img/15/detail/detail_small_15_3.jpg';
import detail_small_15_4 from '../asset/img/mattress_img/15/detail/detail_small_15_4.jpg';
import detail_small_15_5 from '../asset/img/mattress_img/15/detail/detail_small_15_5.jpg';
import detail_small_15_6 from '../asset/img/mattress_img/15/detail/detail_small_15_6.jpg';
import detail_small_15_7 from '../asset/img/mattress_img/15/detail/detail_small_15_7.jpg';
import detail_mobile_15_1 from '../asset/img/mattress_img/15/detail/detail_mobile_15_1.jpg';
import detail_mobile_15_2 from '../asset/img/mattress_img/15/detail/detail_mobile_15_2.jpg';
import detail_mobile_15_3 from '../asset/img/mattress_img/15/detail/detail_mobile_15_3.jpg';
import detail_mobile_15_4 from '../asset/img/mattress_img/15/detail/detail_mobile_15_4.jpg';
import detail_mobile_15_5 from '../asset/img/mattress_img/15/detail/detail_mobile_15_5.jpg';
import detail_mobile_15_6 from '../asset/img/mattress_img/15/detail/detail_mobile_15_6.jpg';
import detail_mobile_15_7 from '../asset/img/mattress_img/15/detail/detail_mobile_15_7.jpg';

import detail_big_16_1 from '../asset/img/mattress_img/16/detail/detail_big_16_1.jpg';
import detail_big_16_2 from '../asset/img/mattress_img/16/detail/detail_big_16_2.jpg';
import detail_big_16_3 from '../asset/img/mattress_img/16/detail/detail_big_16_3.jpg';
import detail_big_16_4 from '../asset/img/mattress_img/16/detail/detail_big_16_4.jpg';
import detail_big_16_5 from '../asset/img/mattress_img/16/detail/detail_big_16_5.jpg';
import detail_big_16_6 from '../asset/img/mattress_img/16/detail/detail_big_16_6.jpg';
import detail_big_16_7 from '../asset/img/mattress_img/16/detail/detail_big_16_7.jpg';
import detail_small_16_1 from '../asset/img/mattress_img/16/detail/detail_small_16_1.jpg';
import detail_small_16_2 from '../asset/img/mattress_img/16/detail/detail_small_16_2.jpg';
import detail_small_16_3 from '../asset/img/mattress_img/16/detail/detail_small_16_3.jpg';
import detail_small_16_4 from '../asset/img/mattress_img/16/detail/detail_small_16_4.jpg';
import detail_small_16_5 from '../asset/img/mattress_img/16/detail/detail_small_16_5.jpg';
import detail_small_16_6 from '../asset/img/mattress_img/16/detail/detail_small_16_6.jpg';
import detail_small_16_7 from '../asset/img/mattress_img/16/detail/detail_small_16_7.jpg';
import detail_mobile_16_1 from '../asset/img/mattress_img/16/detail/detail_mobile_16_1.jpg';
import detail_mobile_16_2 from '../asset/img/mattress_img/16/detail/detail_mobile_16_2.jpg';
import detail_mobile_16_3 from '../asset/img/mattress_img/16/detail/detail_mobile_16_3.jpg';
import detail_mobile_16_4 from '../asset/img/mattress_img/16/detail/detail_mobile_16_4.jpg';
import detail_mobile_16_5 from '../asset/img/mattress_img/16/detail/detail_mobile_16_5.jpg';
import detail_mobile_16_6 from '../asset/img/mattress_img/16/detail/detail_mobile_16_6.jpg';
import detail_mobile_16_7 from '../asset/img/mattress_img/16/detail/detail_mobile_16_7.jpg';


import list_main_1_1 from '../asset/img/mattress_img/1/list_main_1_1.png';
import list_main_1_2 from '../asset/img/mattress_img/1/list_main_1_2.png';

import list_main_2_1 from '../asset/img/mattress_img/2/list_main_2_1.png';
import list_main_2_2 from '../asset/img/mattress_img/2/list_main_2_2.png';

import list_main_4_1 from '../asset/img/mattress_img/4/list_main_4_1.png';
import list_main_4_2 from '../asset/img/mattress_img/4/list_main_4_2.png';

import list_main_5_1 from '../asset/img/mattress_img/5/list_main_5_1.png';
import list_main_5_2 from '../asset/img/mattress_img/5/list_main_5_2.png';

import list_main_9_1 from '../asset/img/mattress_img/9/list_main_9_1.png';
import list_main_9_2 from '../asset/img/mattress_img/9/list_main_9_2.jpg';

import list_main_10_1 from '../asset/img/mattress_img/10/list_main_10_1.png';
import list_main_10_2 from '../asset/img/mattress_img/10/list_main_10_2.jpg';

import list_main_11_1 from '../asset/img/mattress_img/11/list_main_11_1.png';
import list_main_11_2 from '../asset/img/mattress_img/11/list_main_11_2.jpg';

import list_main_12_1 from '../asset/img/mattress_img/12/list_main_12_1.png';
import list_main_12_2 from '../asset/img/mattress_img/12/list_main_12_2.jpg';

import list_main_13_1 from '../asset/img/mattress_img/13/list_main_13_1.png';
import list_main_13_2 from '../asset/img/mattress_img/13/list_main_13_2.jpg';

import list_main_14_1 from '../asset/img/mattress_img/14/list_main_14_1.png';
import list_main_14_2 from '../asset/img/mattress_img/14/list_main_14_2.jpg';

import list_main_15_1 from '../asset/img/mattress_img/15/list_main_15_1.png';
import list_main_15_2 from '../asset/img/mattress_img/15/list_main_15_2.jpg';

import list_main_16_1 from '../asset/img/mattress_img/16/list_main_16_1.png';
import list_main_16_2 from '../asset/img/mattress_img/16/list_main_16_2.jpg';

import nav_detail_img_1 from '../asset/img/mattress_img/1/nav_detail_image_1.png';
import nav_detail_img_2 from '../asset/img/mattress_img/2/nav_detail_image_2.png';
import nav_detail_img_4 from '../asset/img/mattress_img/4/nav_detail_image_4.png';
import nav_detail_img_5 from '../asset/img/mattress_img/5/nav_detail_image_5.png';
import nav_detail_img_9 from '../asset/img/mattress_img/9/nav_detail_img_9.jpg';
import nav_detail_img_10 from '../asset/img/mattress_img/10/nav_detail_img_10.jpg';
import nav_detail_img_11 from '../asset/img/mattress_img/11/nav_detail_img_11.jpg';
import nav_detail_img_12 from '../asset/img/mattress_img/12/nav_detail_img_12.jpg';
import nav_detail_img_13 from '../asset/img/mattress_img/13/nav_detail_img_13.jpg';
import nav_detail_img_14 from '../asset/img/mattress_img/14/nav_detail_img_14.jpg';
import nav_detail_img_15 from '../asset/img/mattress_img/15/nav_detail_img_15.jpg';
import nav_detail_img_16 from '../asset/img/mattress_img/16/nav_detail_img_16.jpg';


import nav_img_9 from '../asset/img/mattress_img/9/nav_image_9.jpg';
import nav_img_10 from '../asset/img/mattress_img/10/nav_img_10.jpg';
import nav_img_11 from '../asset/img/mattress_img/11/nav_img_11.jpg';
import nav_img_12 from '../asset/img/mattress_img/12/nav_img_12.jpg';
import nav_img_13 from '../asset/img/mattress_img/13/nav_img_13.jpg';
import nav_img_14 from '../asset/img/mattress_img/14/nav_img_14.jpg';
import nav_img_15 from '../asset/img/mattress_img/15/nav_img_15.jpg';
import nav_img_16 from '../asset/img/mattress_img/16/nav_img_16.jpg';


import detail_mobile_1_1 from '../asset/img/mattress_img/1/detail/detail_mobile_1_1.png';
import detail_mobile_1_2 from '../asset/img/mattress_img/1/detail/detail_mobile_1_2.png';
import detail_mobile_1_3 from '../asset/img/mattress_img/1/detail/detail_mobile_1_3.png';
import detail_mobile_1_4 from '../asset/img/mattress_img/1/detail/detail_mobile_1_4.png';
import detail_mobile_1_5 from '../asset/img/mattress_img/1/detail/detail_mobile_1_5.png';

import detail_mobile_2_1 from '../asset/img/mattress_img/2/detail/detail_mobile_2_1.png';
import detail_mobile_2_2 from '../asset/img/mattress_img/2/detail/detail_mobile_2_2.png';
import detail_mobile_2_3 from '../asset/img/mattress_img/2/detail/detail_mobile_2_3.png';
import detail_mobile_2_4 from '../asset/img/mattress_img/2/detail/detail_mobile_2_4.png';
import detail_mobile_2_5 from '../asset/img/mattress_img/2/detail/detail_mobile_2_5.png';

import detail_mobile_4_1 from '../asset/img/mattress_img/4/detail/detail_mobile_4_1.png';
import detail_mobile_4_2 from '../asset/img/mattress_img/4/detail/detail_mobile_4_2.png';
import detail_mobile_4_3 from '../asset/img/mattress_img/4/detail/detail_mobile_4_3.png';
import detail_mobile_4_4 from '../asset/img/mattress_img/4/detail/detail_mobile_4_4.png';
import detail_mobile_4_5 from '../asset/img/mattress_img/4/detail/detail_mobile_4_5.png';

import detail_mobile_5_1 from '../asset/img/mattress_img/5/detail/detail_mobile_5_1.png';
import detail_mobile_5_2 from '../asset/img/mattress_img/5/detail/detail_mobile_5_2.png';
import detail_mobile_5_3 from '../asset/img/mattress_img/5/detail/detail_mobile_5_3.png';
import detail_mobile_5_4 from '../asset/img/mattress_img/5/detail/detail_mobile_5_4.png';
import detail_mobile_5_5 from '../asset/img/mattress_img/5/detail/detail_mobile_5_5.png';

import detail_mobile_9_1 from '../asset/img/mattress_img/9/detail/detail_mobile_9_1.jpg';
import detail_mobile_9_2 from '../asset/img/mattress_img/9/detail/detail_mobile_9_2.jpg';
import detail_mobile_9_3 from '../asset/img/mattress_img/9/detail/detail_mobile_9_3.jpg';
import detail_mobile_9_4 from '../asset/img/mattress_img/9/detail/detail_mobile_9_4.jpg';
import detail_mobile_9_5 from '../asset/img/mattress_img/9/detail/detail_mobile_9_5.jpg';
import detail_mobile_9_6 from '../asset/img/mattress_img/9/detail/detail_mobile_9_6.jpg';

import detail_mobile_10_1 from '../asset/img/mattress_img/10/detail/detail_mobile_10_1.jpg';
import detail_mobile_10_2 from '../asset/img/mattress_img/10/detail/detail_mobile_10_2.jpg';
import detail_mobile_10_3 from '../asset/img/mattress_img/10/detail/detail_mobile_10_3.jpg';
import detail_mobile_10_4 from '../asset/img/mattress_img/10/detail/detail_mobile_10_4.jpg';
import detail_mobile_10_5 from '../asset/img/mattress_img/10/detail/detail_mobile_10_5.jpg';
import detail_mobile_10_6 from '../asset/img/mattress_img/10/detail/detail_mobile_10_6.jpg';
import detail_mobile_10_8 from '../asset/img/mattress_img/10/detail/detail_mobile_10_8.jpg';

import detail_mobile_11_1 from '../asset/img/mattress_img/11/detail/detail_mobile_11_1.jpg';
import detail_mobile_11_2 from '../asset/img/mattress_img/11/detail/detail_mobile_11_2.jpg';
import detail_mobile_11_3 from '../asset/img/mattress_img/11/detail/detail_mobile_11_3.jpg';
import detail_mobile_11_4 from '../asset/img/mattress_img/11/detail/detail_mobile_11_4.jpg';
import detail_mobile_11_5 from '../asset/img/mattress_img/11/detail/detail_mobile_11_5.jpg';
import detail_mobile_11_6 from '../asset/img/mattress_img/11/detail/detail_mobile_11_6.jpg';
import detail_mobile_11_7 from '../asset/img/mattress_img/11/detail/detail_mobile_11_7.jpg';

import detail_mobile_12_1 from '../asset/img/mattress_img/12/detail/detail_mobile_12_1.jpg';
import detail_mobile_12_2 from '../asset/img/mattress_img/12/detail/detail_mobile_12_2.jpg';
import detail_mobile_12_3 from '../asset/img/mattress_img/12/detail/detail_mobile_12_3.jpg';
import detail_mobile_12_4 from '../asset/img/mattress_img/12/detail/detail_mobile_12_4.jpg';
import detail_mobile_12_5 from '../asset/img/mattress_img/12/detail/detail_mobile_12_5.jpg';
import detail_mobile_12_6 from '../asset/img/mattress_img/12/detail/detail_mobile_12_6.jpg';
import detail_mobile_12_7 from '../asset/img/mattress_img/12/detail/detail_mobile_12_7.jpg';


import hard_type_S from '../asset/img/hardType/hard_type_S.png';
import hard_type_M from '../asset/img/hardType/hard_type_M.png';
import hard_type_MH from '../asset/img/hardType/hard_type_MH.png';
import hard_type_H from '../asset/img/hardType/hard_type_H.png';

import detail_main_img_1 from '../asset/img/mattress_img/1/detail_main_img_1.png';
import detail_main_img_2 from '../asset/img/mattress_img/2/detail_main_img_2.png';
import detail_main_img_4 from '../asset/img/mattress_img/4/detail_main_img_4.png';
import detail_main_img_5 from '../asset/img/mattress_img/5/detail_main_img_5.png';
import detail_main_img_9 from '../asset/img/mattress_img/9/detail_main_img_9.png';
import detail_main_img_10 from '../asset/img/mattress_img/10/detail_main_img_10.png';
import detail_main_img_11 from '../asset/img/mattress_img/11/detail_main_img_11.png';
import detail_main_img_12 from '../asset/img/mattress_img/12/detail_main_img_12.png';
import detail_main_img_13 from '../asset/img/mattress_img/13/detail_main_img_13.png';
import detail_main_img_14 from '../asset/img/mattress_img/14/detail_main_img_14.png';
import detail_main_img_15 from '../asset/img/mattress_img/15/detail_main_img_15.png';
import detail_main_img_16 from '../asset/img/mattress_img/16/detail_main_img_16.png';


import detail_main_img_2_1 from '../asset/img/mattress_img/2/detail_main_img_2_1.png';
import detail_main_img_2_2 from '../asset/img/mattress_img/2/detail_main_img_2_2.png';


import sleep_maker_movie_main from '../asset/img/mattress_img/movie/sleepmaker.mp4';
import sleep_maker_movie_gif_1 from '../asset/img/mattress_img/movie/sleep_maker_movie.gif';
import sleep_maker_movie_gif_2 from '../asset/img/mattress_img/movie/not_sleep_maker_movie.gif';

export default [
    {
        'no': 1,
        'title': '앨리스 포켓 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'detail_main_img' : detail_main_img_1,
        'detail_img_big': [detail_big_1_1, detail_big_1_2, detail_big_1_3, detail_big_1_4, detail_big_1_5],
        'detail_img_small': [detail_small_1_1, detail_small_1_2, detail_small_1_3, detail_small_1_4, detail_small_1_5],
        'detail_mobile_img' : [detail_mobile_1_1,detail_mobile_1_2,detail_mobile_1_3,detail_mobile_1_4,detail_mobile_1_5],
        'nav_img': detail_small_1_1,
        'nav_detail_img': nav_detail_img_1,
        'list_img': [list_main_1_1, list_main_1_2],
        'hard_type_img' : hard_type_MH,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/5814443218',
        'firstPrice': '2,250,000',
        'price': {
            K: '2,050,000',
            Q: '1,750,000',
            SS: '1,450,000'
        }
    },
    {
        'no': 2,
        'title': '알란 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'sleep_maker' : true,
        'detail_main_img' : [detail_main_img_2,detail_main_img_2_1,detail_main_img_2_2,sleep_maker_movie_main,sleep_maker_movie_gif_1,sleep_maker_movie_gif_2],
        'detail_img_big': [detail_big_2_1, detail_big_2_2, detail_big_2_3, detail_big_2_4, detail_big_2_5],
        'detail_img_small': [detail_small_2_1, detail_small_2_2, detail_small_2_3, detail_small_2_4, detail_small_2_5],
        'detail_mobile_img' : [detail_mobile_2_1,detail_mobile_2_2,detail_mobile_2_3,detail_mobile_2_4,detail_mobile_2_5],
        'nav_img': detail_small_2_1,
        'nav_detail_img': nav_detail_img_2,
        'list_img': [list_main_2_1, list_main_2_2],
        'hard_type_img' : hard_type_S,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/5814317174',
        'firstPrice': '3,750,000',
        'price': {
            K: '3,550,000',
            Q: '3,250,000',
            SS: '2,950,000'
        }
    },
    // {
    //     'no': 3,
    //     'title': '라비아 메모리폼 매직 매트리스',
    //     'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
    //     'detail_main_img' : detail_main_img_3,
    //     'detail_img_big': [detail_big_3_1, detail_big_3_2, detail_big_3_3, detail_big_3_4, detail_big_3_5],
    //     'detail_img_small': [detail_small_3_1, detail_small_3_2, detail_small_3_3, detail_small_3_4, detail_small_3_5],
    //     'detail_mobile_img' : [detail_mobile_3_1,detail_mobile_3_2,detail_mobile_3_3,detail_mobile_3_4,detail_mobile_3_5],
    //     'nav_img': detail_small_3_1,
    //     'nav_detail_img': nav_detail_img_3,
    //     'list_img': [list_main_3_1, list_main_3_2],
    //     'hard_type_img' : hard_type_SM,
    //     'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/5814554505',
    //     'firstPrice': '2,750,000',
    //     'price': {
    //         K: '2,550,000',
    //         Q: '2,250,000',
    //         SS: '1,950,000'
    //     }
    // },
    {
        'no': 4,
        'title': '졸리 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'detail_main_img' : detail_main_img_4,
        'detail_img_big': [detail_big_4_1, detail_big_4_2, detail_big_4_3, detail_big_4_4, detail_big_4_5],
        'detail_img_small': [detail_small_4_1, detail_small_4_2, detail_small_4_3, detail_small_4_4, detail_small_4_5],
        'detail_mobile_img' : [detail_mobile_4_1,detail_mobile_4_2,detail_mobile_4_3,detail_mobile_4_4,detail_mobile_4_5],
        'nav_img': detail_small_4_1,
        'nav_detail_img': nav_detail_img_4,
        'list_img': [list_main_4_1, list_main_4_2],
        'hard_type_img' : hard_type_M,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/5814479626',
        'firstPrice': '2,400,000',
        'price': {
            K: '2,200,000',
            Q: '1,900,000',
            SS: '1,600,000'
        }
    }, {
        'no': 5,
        'title': '베르디 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'detail_main_img' : detail_main_img_5,
        'detail_img_big': [detail_big_5_1, detail_big_5_2, detail_big_5_3, detail_big_5_4, detail_big_5_5],
        'detail_img_small': [detail_small_5_1, detail_small_5_2, detail_small_5_3, detail_small_5_4, detail_small_5_5],
        'detail_mobile_img' : [detail_mobile_5_1,detail_mobile_5_2,detail_mobile_5_3,detail_mobile_5_4,detail_mobile_5_5],
        'nav_img': detail_small_5_1,
        'nav_detail_img': nav_detail_img_5,
        'list_img': [list_main_5_1, list_main_5_2],
        'hard_type_img' : hard_type_MH,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/5814536618',
        'firstPrice': '3,750,000',
        'price': {
            K: '3,550,000',
            Q: '3,250,000',
            SS: '2,950,000'
        }
    },
    // {
    //     'no': 6,
    //     'title': '로즈 메모리폼 매직 매트리스',
    //     'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
    //     'detail_main_img' : detail_main_img_6,
    //     'detail_img_big': [detail_big_6_1, detail_big_6_2, detail_big_6_3, detail_big_6_4, detail_big_6_5],
    //     'detail_img_small': [detail_small_6_1, detail_small_6_2, detail_small_6_3, detail_small_6_4, detail_small_6_5],
    //     'detail_mobile_img' : [detail_mobile_6_1,detail_mobile_6_2,detail_mobile_6_3,detail_mobile_6_4,detail_mobile_6_5],
    //     'nav_img': detail_small_6_1,
    //     'nav_detail_img': nav_detail_img_6,
    //     'list_img': [list_main_6_1, list_main_6_2],
    //     'hard_type_img' : hard_type_MH,
    //     'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/5814511480',
    //     'firstPrice': '2,400,000',
    //     'price': {
    //         K: '2,200,000',
    //         Q: '1,900,000',
    //         SS: '1,600,000'
    //     }
    // },
    // {
    //     'no': 8,
    //     'title': '오로라 메모리폼 매직 매트리스',
    //     'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
    //     'detail_main_img' : detail_main_img_8,
    //     'detail_img_big': [detail_big_8_1, detail_big_8_2, detail_big_8_3, detail_big_8_4, detail_big_8_5],
    //     'detail_img_small': [detail_big_8_1, detail_big_8_2, detail_big_8_3, detail_big_8_4, detail_big_8_5],
    //     'detail_mobile_img' : [detail_mobile_6_1,detail_mobile_6_2,detail_mobile_6_3,detail_mobile_6_4,detail_mobile_6_5],
    //     'nav_img': detail_small_6_1,
    //     'nav_detail_img': nav_detail_img_6,
    //     'list_img': [list_main_8_1, list_main_8_2],
    //     'hard_type_img' : hard_type_MH,
    //     'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/10576802755',
    //     'firstPrice': '9,900,000',
    //     'price': {
    //         K: '9,900,000',
    //         Q: '9,900,000',
    //         SS: '9,900,000'
    //     }
    // },
    {
        'no': 9,
        'title': '레이나 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'sleep_maker' : false,
        'detail_main_img' : detail_main_img_9,
        'detail_img_big': [detail_big_9_1, detail_big_9_2, detail_big_9_3, detail_big_9_4, detail_big_9_5, detail_big_9_6],
        'detail_img_small': [detail_small_9_1, detail_small_9_2, detail_small_9_3, detail_small_9_4, detail_small_9_5, detail_small_9_6],
        'detail_mobile_img' : [detail_mobile_9_1,detail_mobile_9_2,detail_mobile_9_3,detail_mobile_9_4,detail_mobile_9_5,detail_mobile_9_6],
        'nav_img': nav_img_9,
        'nav_detail_img': nav_detail_img_9,
        'list_img': [list_main_9_1, list_main_9_2],
        'hard_type_img' : hard_type_H,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/11516582163',
        'firstPrice': '2,500,000',
        'price': {
            K: '2,800,000',
            Q: '2,500,000',
            SS: '2,800,000'
        }
    },
    {
        'no': 10,
        'title': '루시 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'sleep_maker' : false,
        'detail_main_img' : detail_main_img_10,
        'detail_img_big': [detail_big_10_1, detail_big_10_2, detail_big_10_3, detail_big_10_4, detail_big_10_5, detail_big_10_6, detail_big_10_8],
        'detail_img_small': [detail_small_10_1, detail_small_10_2, detail_small_10_3, detail_small_10_4, detail_small_10_5, detail_small_10_6, detail_small_10_8],
        'detail_mobile_img' : [detail_mobile_10_1, detail_mobile_10_2, detail_mobile_10_3, detail_mobile_10_4, detail_mobile_10_5, detail_mobile_10_6, detail_mobile_10_8],
        'nav_img': nav_img_10,
        'nav_detail_img': nav_detail_img_10,
        'list_img': [list_main_10_1, list_main_10_2],
        'hard_type_img' : hard_type_H,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/11516611886',
        'firstPrice': '3,250,000',
        'price': {
            K: '3,550,000',
            Q: '3,250,000',
            SS: '2,950,000'
        }
    },
    {
        'no': 11,
        'title': '벨라 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'sleep_maker' : false,
        'detail_main_img' : detail_main_img_11,
        'detail_img_big': [detail_big_11_1, detail_big_11_2, detail_big_11_3, detail_big_11_4, detail_big_11_5, detail_big_11_6, detail_big_11_7],
        'detail_img_small': [detail_small_11_1, detail_small_11_2, detail_small_11_3, detail_small_11_4, detail_small_11_5, detail_small_11_6, detail_small_11_7],
        'detail_mobile_img' : [detail_mobile_11_1, detail_mobile_11_2, detail_mobile_11_3, detail_mobile_11_4, detail_mobile_11_5, detail_mobile_11_6, detail_mobile_11_7],
        'nav_img': nav_img_11,
        'nav_detail_img': nav_detail_img_11,
        'list_img': [list_main_11_1, list_main_11_2],
        'hard_type_img' : hard_type_H,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/11516628302',
        'firstPrice': '2,500,000',
        'price': {
            K: '2,800,000',
            Q: '2,500,000',
            SS: '2,200,000'
        }
    },
    {
        'no': 12,
        'title': '오로라 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'sleep_maker' : false,
        'detail_main_img' : detail_main_img_12,
        'detail_img_big': [ detail_big_12_1, detail_big_12_2, detail_big_12_3, detail_big_12_4, detail_big_12_5, detail_big_12_6, detail_big_12_7],
        'detail_img_small': [detail_small_12_1, detail_small_12_2, detail_small_12_3, detail_small_12_4, detail_small_12_5, detail_small_12_6, detail_small_12_7],
        'detail_mobile_img' : [detail_mobile_12_1, detail_mobile_12_2, detail_mobile_12_3, detail_mobile_12_4, detail_mobile_12_5, detail_mobile_12_6, detail_mobile_12_7],
        'nav_img': nav_img_12,
        'nav_detail_img': nav_detail_img_12,
        'list_img': [list_main_12_1, list_main_12_2],
        'hard_type_img' : hard_type_H,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/11516646114',
        'firstPrice': '2,500,000',
        'price': {
            K: '2,800,000',
            Q: '2,500,000',
            SS: '2,200,000'
        }
    },
    {
        'no': 13,
        'title': '루나 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'sleep_maker' : false,
        'detail_main_img' : detail_main_img_13,
        'detail_img_big': [ detail_big_13_1, detail_big_13_2, detail_big_13_3, detail_big_13_4, detail_big_13_5, detail_big_13_6, detail_big_13_7],
        'detail_img_small': [detail_small_13_1, detail_small_13_2, detail_small_13_3, detail_small_13_4, detail_small_13_5, detail_small_13_6, detail_small_13_7],
        'detail_mobile_img' : [detail_mobile_13_1, detail_mobile_13_2, detail_mobile_13_3, detail_mobile_13_4, detail_mobile_13_5, detail_mobile_13_6, detail_mobile_13_7],
        'nav_img': nav_img_13,
        'nav_detail_img': nav_detail_img_13,
        'list_img': [list_main_13_1, list_main_13_2],
        'hard_type_img' : hard_type_H,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/11516671909',
        'firstPrice': '1,650,000',
        'price': {
            K: '2,200,000',
            Q: '1,900,000',
            SS: '1,600,000'
        }
    },
    {
        'no': 14,
        'title': '하이디 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'sleep_maker' : false,
        'detail_main_img' : detail_main_img_14,
        'detail_img_big': [detail_big_14_1, detail_big_14_2, detail_big_14_3, detail_big_14_4, detail_big_14_5, detail_big_14_6, detail_big_14_7],
        'detail_img_small': [detail_small_14_1, detail_small_14_2, detail_small_14_3, detail_small_14_4, detail_small_14_5, detail_small_14_6, detail_small_14_7],
        'detail_mobile_img' : [detail_mobile_14_1, detail_mobile_14_2, detail_mobile_14_3, detail_mobile_14_4, detail_mobile_14_5, detail_mobile_14_6, detail_mobile_14_7],
        'nav_img': nav_img_14,
        'nav_detail_img': nav_detail_img_14,
        'list_img': [list_main_14_1, list_main_14_2],
        'hard_type_img' : hard_type_H,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/11516687996',
        'firstPrice': '2,500,000',
        'price': {
            K: '2,500,000',
            Q: '2,200,000',
            SS: '1,900,000'
        }
    },
    {
        'no': 15,
        'title': '올리비아 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'sleep_maker' : false,
        'detail_main_img' : detail_main_img_15,
        'detail_img_big': [detail_big_15_1, detail_big_15_2, detail_big_15_3, detail_big_15_4, detail_big_15_5, detail_big_15_6, detail_big_15_7],
        'detail_img_small': [detail_small_15_1, detail_small_15_2, detail_small_15_3, detail_small_15_4, detail_small_15_5, detail_small_15_6, detail_small_15_7],
        'detail_mobile_img' : [detail_mobile_15_1, detail_mobile_15_2, detail_mobile_15_3, detail_mobile_15_4, detail_mobile_15_5, detail_mobile_15_6, detail_mobile_15_7],
        'nav_img': nav_img_15,
        'nav_detail_img': nav_detail_img_15,
        'list_img': [list_main_15_1, list_main_15_2],
        'hard_type_img' : hard_type_H,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/11516702516',
        'firstPrice': '3,250,000',
        'price': {
            K: '3,550,000',
            Q: '3,250,000',
            SS: '2,950,000'
        }
    },
    {
        'no': 16,
        'title': '노바 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'sleep_maker' : false,
        'detail_main_img' : detail_main_img_16,
        'detail_img_big': [ detail_big_16_1, detail_big_16_2, detail_big_16_3, detail_big_16_4, detail_big_16_5, detail_big_16_6, detail_big_16_7],
        'detail_img_small': [detail_small_16_1, detail_small_16_2, detail_small_16_3, detail_small_16_4, detail_small_16_5, detail_small_16_6, detail_small_16_7],
        'detail_mobile_img' : [detail_mobile_16_1, detail_mobile_16_2, detail_mobile_16_3, detail_mobile_16_4, detail_mobile_16_5, detail_mobile_16_6, detail_mobile_16_7],
        'nav_img': nav_img_16,
        'nav_detail_img': nav_detail_img_16,
        'list_img': [list_main_16_1, list_main_16_2],
        'hard_type_img' : hard_type_H,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/11516714710',
        'firstPrice': '3,500,000',
        'price': {
            K: '3,800,000',
            Q: '3,500,000',
            SS: '3,200,000'
        }
    },
]
